import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SPONSOR_ENTITY_LIST_FRAGMENT = `
  fragment sponsorEntityListFragment on Sponsor {
    uid
    __typename
    name
    description
    url
    type
    logoFileResource {
      ...fileResourceBaseFragment
    }
    exhibitor {
      uid 
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
